import React, { useState, useRef, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MarkerType,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
import { useSelector } from "react-redux";
import "reactflow/dist/style.css";
import "./flowStyle.css";

import Sidebar from "./Slidebar.component";
import Faucet from "../../assets/images/faucet.svg";
// import ButtonEdge from './ButtonEdge.component';
// import CustomNode from './CustomNode.component';

/*#e8effa water source
#456bac water user
#a2b9e6 flow
#fd942b changes
#24375c border*/

// const nodeSourceStyle = {
//   background: '#456bac',
//   color: '#ffffff',
//   borderRadius: '10px',
//   border: '1px solid #24375c',
//   padding: '2px 7px',
//   fontSize: '14px'
// };

// const nodeUserStyle = {
//   background: '#e8effa',
//   color: '#24375c',
//   borderRadius: '10px',
//   border: '1px solid #24375c',
//   padding: '2px 7px',
//   fontSize: '14px'
// };

// const edgeStyle = {
//   //     markerStart: 'faucet',
//   markerEnd: {
//     type: MarkerType.ArrowClosed,
//     width: 7,
//     height: 7,
//     color: '#a2b9e6',
//   },
//   label: '',
//   style: {
//     strokeWidth: 4,
//     stroke: '#a2b9e6',
//   },
// };

// const edgeTypes = ['default', 'step', 'smoothstep', 'straight'];

// const edgeTypes = {
//   buttonedge: ButtonEdge,
// };

// const nodeTypes = {
//   customNode: CustomNode,
// };

// let initialNodes = [];
// let initialEdges = [];
// let id = initialNodes.length;
// const getId = () => `node_${id++}`;

const OverviewFlow = () => {
  // const waterSystemSelected = useSelector((state) => state.waterSystem.data);
  const waterSystems = useSelector((state) => state.waterSystems).data;
  // const waterSystem = waterSystems?.find((f) => f.id === waterSystemSelected);
  const waterAuthority = useSelector((state) => state.waterAuthority).data;
  const flowSystem = waterAuthority?.attributes?.diagramSchema;
  const initialNodes = flowSystem?.nodes ?? [];
  const initialEdges = flowSystem?.edges ?? [];
  // let id = initialNodes.length;
  // const getId = () => `node_${id++}`;

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  // const [reactFlowInstance, setReactFlowInstance] = useState(null);

  // const onEdgeRemove = (edgeId) => {
  //   setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== edgeId));
  // };

  // const onConnect = useCallback(
  //   (connection) => {
  //     connection.markerEnd = edgeStyle.markerEnd;
  //     connection.style = edgeStyle.style;
  //     // connection.type = edgeTypes[2];
  //     connection.type = 'buttonedge';
  //     connection.data = { onRemove: onEdgeRemove }
  //     setEdges((eds) => addEdge(connection, eds));
  //     console.log('nodes', nodes);
  //     console.log('edges', edges, connection);
  //   },
  //   [setEdges]
  // );

  // const onDragOver = useCallback((event) => {
  //   event.preventDefault();
  //   event.dataTransfer.dropEffect = 'move';
  // }, []);

  // const onNodeRemove = (nodeId) => {
  //   setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
  // };

  // const onNodeEdit = (nodeId, data) => {
  //   setNodes((prevNodes) => {
  //     return prevNodes.map((node) => node.id === nodeId ? {
  //       ...node, data:
  //       {
  //         label: data.name,
  //         uid: data.uid,
  //         onRemove: onNodeRemove,
  //         onEdit: onNodeEdit
  //       }
  //     } : node)
  //   });
  // };

  // const onDrop = useCallback(
  //   (event) => {
  //     event.preventDefault();

  //     const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
  //     const type = event.dataTransfer.getData('application/reactflow');

  //     if (typeof type === 'undefined' || !type) {
  //       return;
  //     }

  //     const sourceName = 'Water source';
  //     const userName = 'Water user';
  //     const id = getId();
  //     const label = type === 'output' ? `${userName} ${id}` : `${sourceName} ${id}`;
  //     const nodeStyle = type === 'output' ? nodeSourceStyle : nodeUserStyle;

  //     const position = reactFlowInstance.project({
  //       x: event.clientX - reactFlowBounds.left,
  //       y: event.clientY - reactFlowBounds.top,
  //     });
  //     const newNode = {
  //       id: id,
  //       type: 'customNode',
  //       position,
  //       sourcePosition: 'right',
  //       targetPosition: 'left',
  //       data: {
  //         label: label,
  //         uid: '',
  //         onRemove: onNodeRemove,
  //         onEdit: onNodeEdit
  //       },
  //       style: nodeStyle,
  //     };
  //     setNodes((nds) => nds.concat(newNode));
  //   },
  //   [reactFlowInstance]
  // );

  // const onNodesChange = useCallback(
  //   (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
  //   [setNodes]
  // );
  // const onEdgesChange = useCallback(
  //   (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
  //   [setEdges]
  // );
  // const onConnect = useCallback(
  //   (connection) => setEdges((eds) => addEdge(connection, eds)),
  //   [setEdges]
  // );

  // const onNodesChange = useCallback(
  //   (changes) => {
  //     setNodes((nds) => applyNodeChanges(changes, nds));
  //     nodes.map(n => n.data.onRemove = onNodeRemove)
  //     nodes.map(n => n.data.onEdit = onNodeEdit)
  //   }, [setNodes]
  // );

  // const onEdgesChange = useCallback(
  //   (changes) => {
  //     setEdges((eds) => applyEdgeChanges(changes, eds))
  //     edges.map(ed => ed.data.onRemove = onEdgeRemove)
  //     console.log('edges changes', changes, edges)
  //   }, [setEdges]
  // );

  const onNodesChange = useCallback(
    (changes) => {
      console.log("onNodesChange", nodes);
      return setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      console.log("onEdgesChange", edges);
      return setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const onConnect = useCallback(
    (connection) => {
      console.log("onConnect", edges);
      return setEdges((eds) => addEdge(connection, eds));
    },
    [setEdges]
  );

  return (
    // <>
    //   <img src={Faucet} alt="React Logo" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', minHeight: '600px' }} />
    <div className="dndflow overviewFlow">
      <ReactFlowProvider>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodesDraggable={false}
            zoomOnDoubleClick={false}
            zoomOnScroll={false}
            onConnect={() => {}}
            onDragOver={() => {}}
            onDrop={() => {}}
            // onInit={setReactFlowInstance}
            // onDrop={onDrop}
            // onDragOver={onDragOver}
            // nodeTypes={nodeTypes}
            // edgeTypes={edgeTypes}
            fitView
          >
            {/* <Controls /> */}
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
        </div>
        {/* <Sidebar flow={{ nodes, edges }} /> */}
      </ReactFlowProvider>
    </div>
    // </>
  );
};

export default OverviewFlow;
