import React, { useState, useRef, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MarkerType,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
import "reactflow/dist/style.css";

import Sidebar from "./Slidebar.component";

import "./flowStyle.css";

import Faucet from "../../assets/images/faucet.svg";
import ButtonEdge from "./ButtonEdge.component";
import { useSelector } from "react-redux";
import CustomNode from "./CustomNode.component";
import { t } from "i18next";

/*#e8effa water source
#456bac water user
#a2b9e6 flow
#fd942b changes
#24375c border*/

const nodeSourceStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "42px",
  background: "#456bac",
  color: "#ffffff",
  borderRadius: "10px",
  border: "1px solid #24375c",
  padding: "2px 7px",
  fontSize: "14px",
};

const nodeUserStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "42px",
  background: "#e8effa",
  color: "#24375c",
  borderRadius: "10px",
  border: "1px solid #24375c",
  padding: "2px 7px",
  fontSize: "14px",
};

const edgeStyle = {
  //     markerStart: 'faucet',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 7,
    height: 7,
    color: "#a2b9e6",
  },
  label: "",
  style: {
    strokeWidth: 4,
    stroke: "#a2b9e6",
  },
};

// const edgeTypes = ['default', 'step', 'smoothstep', 'straight', {buttonedge: ButtonEdge}];

const edgeTypes = {
  buttonedge: ButtonEdge,
};

const nodeTypes = {
  customNode: CustomNode,
};

const SetupFlow = () => {
  // const waterSystemSelected = useSelector((state) => state.waterSystem.data);
  // const waterSystems = useSelector((state) => state.waterSystems).data;
  // const waterSystem = waterSystems?.find((f) => f.id === waterSystemSelected);
  const waterAuthority = useSelector((state) => state.waterAuthority).data;
  const flowSystem = waterAuthority?.attributes?.diagramSchema;
  const initialNodes = flowSystem?.nodes ?? [];
  const initialEdges = flowSystem?.edges ?? [];
  let id = initialNodes.length;
  const getId = () => `node_${id++}`;

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onEdgeRemove = (edgeId) => {
    setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== edgeId));
    // console.log("onEdgeRemove", nodes, edges);
  };

  const onConnect = useCallback(
    (connection) => {
      connection.markerEnd = edgeStyle.markerEnd;
      connection.style = edgeStyle.style;
      connection.type = "buttonedge";
      connection.data = { onRemove: onEdgeRemove };
      setEdges((eds) => addEdge(connection, eds));
      // console.log("nodes", nodes);
      // console.log("edges", edges, connection);
      // console.log("onNodesChange", nodes);
    },
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodeRemove = (nodeId) => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
    setEdges((prevEdges) =>
      prevEdges.filter((edge) => {
        return edge.target !== nodeId && edge.source !== nodeId;
      })
    );
  };

  const onNodeEdit = (nodeId, data) => {
    setNodes((prevNodes) => {
      return prevNodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                label: data.name,
                uid: data.uid,
                onRemove: onNodeRemove,
                onEdit: onNodeEdit,
              },
            }
          : node
      );
    });
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      if (typeof type === "undefined" || !type) {
        return;
      }

      const sourceName = t("setup-flow-component.source-name");
      const userName = t("setup-flow-component.user-name");
      const id = getId();
      const label =
        type === "output" ? `${userName} ${id}` : `${sourceName} ${id}`;
      const nodeStyle = type === "output" ? nodeSourceStyle : nodeUserStyle;

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: id,
        type: "customNode",
        position,
        sourcePosition: "right",
        targetPosition: "left",
        data: {
          label: label,
          uid: "",
          onRemove: onNodeRemove,
          onEdit: onNodeEdit,
        },
        style: nodeStyle,
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
      setNodes((nds) =>
        nds.map((node) => {
          return {
            ...node,
            type: "customNode",
            data: { ...node.data, onRemove: onNodeRemove, onEdit: onNodeEdit },
          };
        })
      );
    },
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
      setEdges((eds) =>
        eds.map((edge) => {
          return {
            ...edge,
            type: "buttonedge",
            data: { ...edge.data, onRemove: onEdgeRemove },
          };
        })
      );
    },
    [setEdges]
  );

  return (
    // <>
    //   <img src={Faucet} alt="React Logo" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', minHeight: '600px' }} />
    <div className="dndflow setup-flow">
      <ReactFlowProvider>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            fitView
          >
            <Controls />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
        </div>
        <Sidebar flow={{ nodes, edges }} />
      </ReactFlowProvider>
    </div>
    // </>
  );
};

export default SetupFlow;
