import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MapComponent from "shared-components/src/components/map/map.component";
import palette_custom from "../../palette";

const MapWaterHomeComponent = () => {
  const { t } = useTranslation();

  const waterSystems = Array.from(
    useSelector((state) => state?.waterSystems?.data)
  );
  const systemPolygons = waterSystems
    ? waterSystems
        .filter((waterSystem) => waterSystem?.attributes?.polygons?.[0])
        .map((waterSystem) => {
          const polygon = waterSystem?.attributes?.polygons?.[0];
          return {
            ...polygon,
            // popup: waterSystem?.attributes?.name,
            tooltip: waterSystem?.attributes?.name,
          };
        })
    : [];
  const sensors = waterSystems
    ? waterSystems.flatMap(
        (waterSystem) => waterSystem?.attributes?.sensors?.data || []
      )
    : [];

  const setMarkersWithNumber = (sensors, sensors_data) => {
    return !sensors
      ? []
      : sensors
          ?.filter((sensor) => sensor?.attributes?.position?.[0])
          .map((sensor, index) => {
            let popup = null;
            let popupWithTable = null;
            // if (index === 1) {
            //     popup = '<div style="display: flex; align-items: center; background: #fce4d6; padding: 5px; border-radius: 7px;"><div style="background: orange; width: 20px; height: 20px; borderRadius: 50%; margin: 5px"></div>AAAA</div>';
            // }
            // if (index !== 1) {
            //     popupWithTable = {
            //         rows: setSensorDataRealtime(sensor.id, sensors_data),
            //         tableTitle: sensor.name
            //     }
            // }
            let color = palette_custom.primary;
            let iconNumber = index + 1;
            if (sensor.attributes.type === "water_source") {
              color = palette_custom.marker;
              iconNumber = "S";
            }
            return {
              id: sensor.id,
              position: sensor.attributes.position,
              iconNumber: iconNumber,
              color: color,
              popup: popup,
              popupWithTable: popupWithTable,
              tooltip: sensor.attributes.name,
            };
          });
  };

  const markersWithNumber = setMarkersWithNumber(sensors, null);

  return (
    <Fragment>
      <MapComponent
        shapefile={null}
        polygons={systemPolygons}
        layers={null}
        markersWithNumber={markersWithNumber}
      ></MapComponent>
      <div
        style={{ display: "flex", alignItems: "center", background: "#ffffff" }}
      >
        <div
          style={{
            background: palette_custom.marker,
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            margin: "5px",
          }}
        ></div>
        <span style={{ fontWeight: "bold" }}>
          {t("map-oveview.water-source")}
        </span>
        <div
          style={{
            background: palette_custom.primary,
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            margin: "5px",
            marginLeft: "25px",
          }}
        ></div>
        <span style={{ fontWeight: "bold" }}>{t("map-oveview.sensors")}</span>
      </div>
    </Fragment>
  );
};

export default MapWaterHomeComponent;
