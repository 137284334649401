import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { updateSensorRequest } from "../../store/actions/sensors.actions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { sensorType } from "../../config/sensorTypeEnum";
import MapDrawMarkerComponent from "shared-components/src/components/map/mapDrawMarker.component";

const UpdateSensorsComponent = ({ sensor, open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const waterSystems = useSelector((state) => state?.waterSystems?.data);

  const getSystemName = (eui) => {
    const system = waterSystems.find((ws) =>
      ws.attributes.sensors.data.some((sensor) => sensor.attributes.eui === eui)
    );
    console.log(system.id);
    return system ? system.id : "";
  };

  const initialValues = {
    waterSystem: getSystemName(sensor?.attributes?.eui) || "",
    type: sensor?.attributes?.type || "",
    eui: sensor?.attributes?.eui || "",
    name: sensor?.attributes?.name || "",
    latitude: sensor?.attributes?.position[0] || "",
    longitude: sensor?.attributes?.position[1] || "",
  };
  console.log("initialValues:", initialValues);

  const validationSchema = Yup.object({
    waterSystem: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    eui: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    latitude: Yup.number().typeError("Latitude value is not valid"),
    longitude: Yup.number().typeError("Longitude value is not valid"),
  });

  const handleSave = (values) => {
    const updatedSensor = {
      ...sensor,
      type: values.type,
      eui: values.eui,
      position: [values.latitude, values.longitude],
      name: values.name,
      water_system: values.waterSystem,
    };
    dispatch(updateSensorRequest(updatedSensor)).catch((error) => {
      console.error("Errore durante l'aggiornamento del sensore:", error);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "70%",
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {(formikProps) => (
          <Form>
            <DialogTitle id="alert-dialog-title">
              {t("setup-sensors-page.update-form-title")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="water-system-select-label">
                            {t("setup-sensors-page.add.source-user-label")}
                          </InputLabel>
                          <Select
                            label="Type of sensor"
                            id={`waterSystem`}
                            name={`waterSystem`}
                            labelId="water-system-select-label"
                            value={formikProps.values.waterSystem}
                            onChange={(e) =>
                              formikProps.setFieldValue(
                                "waterSystem",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value="">
                              <em>{t("none")}</em>
                            </MenuItem>
                            {Object.values(waterSystems).map(
                              (waterSystem, i) => (
                                <MenuItem key={i} value={waterSystem.id}>
                                  {t(waterSystem.attributes.name)}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          <ErrorMessage name="waterSystem" component="div" />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="type-select-label">
                            {t("setup-sensors-page.add.type-sensor-label")}
                          </InputLabel>
                          <Select
                            label={t(
                              "setup-sensors-page.add.type-sensor-label"
                            )}
                            id={`type`}
                            name={`type`}
                            labelId="type-select-label"
                            value={formikProps.values.type}
                            onChange={(e) =>
                              formikProps.setFieldValue("type", e.target.value)
                            }
                          >
                            <MenuItem value="">
                              <em>{t("none")}</em>
                            </MenuItem>
                            {Object.values(sensorType).map((option, i) => (
                              <MenuItem key={i} value={option}>
                                {t(option)}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage name="type" component="div" />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.name")}
                          name={`name`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`name`} component="div" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.device-eui")}
                          name={`eui`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`eui`} component="div" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.latitude")}
                          name={`latitude`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`latitude`} component="div" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.longitude")}
                          name={`longitude`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`longitude`} component="div" />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <MapDrawMarkerComponent
                      markerPosition={{
                        lat: formikProps?.values?.latitude,
                        lng: formikProps?.values?.longitude,
                      }}
                      onMarkerPositionChange={(value) => {
                        formikProps.setFieldValue("latitude", value?.lat || "");
                        formikProps.setFieldValue(
                          "longitude",
                          value?.lng || ""
                        );
                      }}
                    ></MapDrawMarkerComponent>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                {t("setup-sensors-page.add.cancel")}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {t("setup-sensors-page.btn-update")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateSensorsComponent;
