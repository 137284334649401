import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { updateWaterSystemData } from "../../store/actions/waterSystems.actions";
import MapAddUpdateFieldComponent from "../map/MapAddUpdateField.component";
import FileUploader from "shared-components/src/components/fileUploader/FileUploader.component";
import { useState } from "react";

const UpdateWaterSystemComponent = ({ waterSystem, open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [shapefileData, setShapefileData] = useState(null);
  const isMobile = useMediaQuery("(min-width:600px)");

  const initialValues = {
    name: waterSystem?.attributes?.name,
    polygons: waterSystem?.attributes?.polygons,
  };
  console.log(initialValues.polygons);
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  const handleSave = (value) => {
    const updatedSystem = {
      id: waterSystem.id,
      data: {
        name: value.name,
        polygons: value.polygons,
      },
    };
    dispatch(updateWaterSystemData(updatedSystem));
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"lg"}
      fullWidth={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {(formikDialogProps) => (
          <Form>
            <DialogTitle id="dialog-title">
              {t("setup-water-systems-page.add.button")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="dialog-description">
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={12}>
                    <Stack
                      direction={!isMobile ? "column" : "row"}
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      width="100%"
                    >
                      <Stack spacing={1}>
                        <Field
                          as={TextField}
                          label={t(
                            "setup-water-systems-page.add.field-form-name"
                          )}
                          name="name"
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name="name" component="div" />
                      </Stack>
                      <Stack spacing={1}>
                        <Typography variant="body1">
                          {t("shapefile.label")}
                        </Typography>
                        <FileUploader onUploadSuccess={setShapefileData} />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MapAddUpdateFieldComponent
                      formikDialogProps={{
                        ...formikDialogProps,
                        shapefileData,
                        setShapefileData,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                {t("setup-water-systems-page.add.cancel-button")}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {t("setup-water-systems-page.update-button")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateWaterSystemComponent;
