import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Handle } from "reactflow";
import * as Yup from "yup";
import "./flowStyle.css";
import { useSelector } from "react-redux";

const CustomNode = ({ id, data }) => {
  const { t } = useTranslation();
  const inputId = "input-" + id;
  const outputId = "output-" + id;
  const [open, setOpen] = useState(false);
  const waterSystems = useSelector((state) => state?.waterSystems?.data);
  const initWaterSystem = waterSystems.find(
    (w) => w.attributes.name === data?.label
  );
  const [newWaterSystem, setWaterSystem] = useState(initWaterSystem?.id);

  const initialValues = {
    uid: data?.uid ?? "",
    name: data?.label ?? "",
  };

  const validationSchema = Yup.object({
    uid: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value) => {
    const waterSystem = waterSystems.find((w) => w.id === value?.name);
    value = { ...value, ...{ name: waterSystem?.attributes?.name } };
    data.onEdit(id, value);
    setOpen(false);
  };

  return (
    <div className="custom-node">
      <div className="inputs">
        <Handle key={inputId} type="target" position="left" />
      </div>
      <div className="content">
        <div className="buottons-container">
          <button className="custom-node-button" onClick={handleClickOpen}>
            <div>i</div>
          </button>
          <button
            className="custom-node-button"
            onClick={() => data.onRemove(id)}
          >
            <div>x</div>
          </button>
        </div>
        <div>{data.label}</div>
      </div>
      <div className="outputs">
        <Handle key={outputId} type="source" position="right" />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {(formikDialogProps) => (
            <Form>
              <DialogTitle>{t("custom-node.dialog-title")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("custom-node.dialog-content-text")}
                </DialogContentText>
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="water-system-select-label">
                      {t("custom-node.name-source-user")}
                    </InputLabel>
                    <Select
                      label={t("custom-node.type-sensor")}
                      id={`name`}
                      name={`name`}
                      labelId="water-system-select-label"
                      value={newWaterSystem}
                      onChange={(e) => {
                        setWaterSystem(e.target.value);
                        formikDialogProps.setFieldValue("name", e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>{t("none")}</em>
                      </MenuItem>
                      {Object.values(waterSystems).map((waterSystem, i) => (
                        <MenuItem key={i} value={waterSystem.id}>
                          {t(waterSystem.attributes.name)}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="name" component="div" />
                  </FormControl>
                </div>
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <Field
                    autoFocus
                    as={TextField}
                    label={t("custom-node.flow-node-name")}
                    name={`uid`}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                <Button type="submit" variant="contained" color="primary">
                  {t("save")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default CustomNode;
