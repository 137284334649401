import axios from "axios";
import { API_GET_USERS } from "../../config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  FETCH_USERS_REQUEST,
  fetchUsersFailure,
  fetchUsersRequest,
  fetchUsersSuccess,
  UPDATE_USER_REQUEST,
  updateUserFailure,
  updateUserSuccess,
} from "../../store/actions/users.actions";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";

const getUsersByWaterAuthority = async (token, water_authority) => {
  const url = `${API_GET_USERS}?filters[water_authority][name]=${water_authority}&sort[0]:asc=confirmed`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function* handleUsersByWaterAuthority() {
  try {
    const token = yield select((state) => state.auth.token);
    const water_authority = yield select(
      (state) => state.waterAuthority.data.attributes.name
    );
    const response = yield call(
      getUsersByWaterAuthority,
      token,
      water_authority
    );
    yield put(fetchUsersSuccess(response));
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchUsersFailure(error.message));
  }
}

export default function* watchUsersByWaterAuthority() {
  yield takeLatest(FETCH_USERS_REQUEST, handleUsersByWaterAuthority);
}

const updateUser = async (token, user) => {
  const url = `${API_GET_USERS}/${user.id}`;
  const data = { confirmed: !user.confirmed };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.put(url, data, { headers });
  return response.data;
};

function* handleUpdateUser(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    let user = action?.payload;
    const response = yield call(updateUser, token, user);
    yield put(updateUserSuccess(response));
    yield put(fetchUsersRequest());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(updateUserFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, handleUpdateUser);
}
