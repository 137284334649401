import axios from "axios";
import { API_SENSOR_MEASURE_DATA } from "../../config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import {
  FETCH_WATER_SENSOR_MEASURE_REQUEST,
  fetchWaterSensorMeasureDataFailure,
  fetchWaterSensorMeasureDataSuccess,
} from "../../store/actions/realtimeSensorMeasure.actions";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import dayjs from "dayjs";

const getWaterSensorMeasureData = async (
  token,
  field,
  name,
  start_time,
  end_time
) => {
  const url = `${API_SENSOR_MEASURE_DATA}?field_name=${field}&name=${name}&start_time=${start_time}&end_time=${end_time}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

function* handleWaterSensorMeasureData(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const field = action?.payload.systemName;
    const name = action?.payload.sensorMeasure;
    const start_time = dayjs()
      .subtract(1, "month")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const end_time = dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const response = yield call(
      getWaterSensorMeasureData,
      token,
      field,
      name,
      start_time,
      end_time
    );
    yield put(fetchWaterSensorMeasureDataSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWaterSensorMeasureDataFailure(error.message));
  }
}

export function* watchGetWaterSensorMeasureData() {
  yield takeLatest(
    FETCH_WATER_SENSOR_MEASURE_REQUEST,
    handleWaterSensorMeasureData
  );
}
