import { useTheme } from "@emotion/react";
import { Button, Container, Grid, Paper } from "@mui/material";
import { Fragment } from "react";
import SignupComponent from "../components/signup/signup.component";
import logo from "../assets/images/Acquaount-logo.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routerPaths } from "../router/routerPaths";

const SignupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const onLoginHandler = () => {
    navigate(routerPaths.home);
  };

  return (
    <Fragment>
      <div
        style={{
          background: theme.palette.primary.main,
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={5}>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <SignupComponent logo={logo} onLoginHandler={onLoginHandler} />
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                  style={{ marginTop: "5px" }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      href="/login"
                    >
                      {t("login")}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

export default SignupPage;
