export const FETCH_WATER_AUTHORITIES_REQUEST =
  "FETCH_WATER_AUTHORITIES_REQUEST";
export const FETCH_WATER_AUTHORITIES_SUCCESS =
  "FETCH_WATER_AUTHORITIES_SUCCESS";
export const FETCH_WATER_AUTHORITIES_FAILURE =
  "FETCH_WATER_AUTHORITIES_FAILURE";

export const fetchWaterAuthoritiesRequest = (id) => {
  return {
    type: FETCH_WATER_AUTHORITIES_REQUEST,
    payload: id,
  };
};

export const fetchWaterAuthoritiesSuccess = (data) => {
  return {
    type: FETCH_WATER_AUTHORITIES_SUCCESS,
    payload: data,
  };
};

export const fetchWaterAuthoritiesFailure = () => {
  return {
    type: FETCH_WATER_AUTHORITIES_FAILURE,
  };
};
