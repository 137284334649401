export const FETCH_AGGREGATE_MONTH_MEASURE_REQUEST =
  "FETCH_AGGREGATE_MONTH_MEASURE_REQUEST";
export const FETCH_AGGREGATE_MONTH_MEASURE_SUCCESS =
  "FETCH_AGGREGATE_MONTH_MEASURE_SUCCESS";
export const FETCH_AGGREGATE_MONTH_MEASURE_FAILURE =
  "FETCH_AGGREGATE_MONTH_MEASURE_FAILURE";

export const fetchAggregateMonthMeasureDataRequest = (
  systemName,
  sensorMeasure
) => {
  return {
    type: FETCH_AGGREGATE_MONTH_MEASURE_REQUEST,
    payload: { systemName, sensorMeasure },
  };
};

export const fetchAggregateMonthMeasureDataSuccess = (data) => {
  return {
    type: FETCH_AGGREGATE_MONTH_MEASURE_SUCCESS,
    payload: data,
  };
};

export const fetchAggregateMonthMeasureDataFailure = (error) => {
  return {
    type: FETCH_AGGREGATE_MONTH_MEASURE_FAILURE,
    payload: error,
  };
};
