import {
  FETCH_WATER_AUTHORITY_FAILURE,
  FETCH_WATER_AUTHORITY_REQUEST,
  FETCH_WATER_AUTHORITY_SUCCESS,
  UPDATE_WATER_AUTHORITY_FAILURE,
  UPDATE_WATER_AUTHORITY_REQUEST,
  UPDATE_WATER_AUTHORITY_SUCCESS,
} from "../actions/waterAuthority.actions";

const initialState = {
  data: [],
  loading: false,
};

const waterAuthority = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATER_AUTHORITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_WATER_AUTHORITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_WATER_AUTHORITY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_WATER_AUTHORITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATE_WATER_AUTHORITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_WATER_AUTHORITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default waterAuthority;
