import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_DATA_FROM_MODEL } from "../../config/api";
import {
  FETCH_DATA_FROM_MODEL_REQUEST,
  getDataFromModelFailure,
  getDataFromModelSuccess,
} from "../../store/actions/realtimeDataFromModel.actions";

const getDataFromModel = async (token, system) => {
  const url = `${API_DATA_FROM_MODEL}`;
  const data = { system_name: system.name };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(url, data, { headers });
  return response.data;
};

function* handleDataFromModel(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const system = action?.payload;
    // const response = yield call(getDataFromModel, token, system);
    const response = yield call(mockGetDataFromModel, token, system);
    yield put(getDataFromModelSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(getDataFromModelFailure(error.message));
  }
}

export function* watchGetDataFromModel() {
  yield takeLatest(FETCH_DATA_FROM_MODEL_REQUEST, handleDataFromModel);
}

const mockGetDataFromModel = async (token, system) => {
  await new Promise((resolve) => setTimeout(resolve, 1000));

  return {
    data: [
      {
        datetime: "2024-01-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "1.135483871",
        max: "94.11129032",
        mean: "25.97365591",
      },
      {
        datetime: "2024-02-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0.1875",
        max: "132.1214286",
        mean: "40.00742508",
      },
      {
        datetime: "2024-03-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0.070967742",
        max: "69.76935484",
        mean: "25.18521505",
      },
      {
        datetime: "2024-04-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "1.371666667",
        max: "23.06833333",
        mean: "6.549583333",
      },
      {
        datetime: "2024-05-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0.119354839",
        max: "145.0129032",
        mean: "15.68965054",
      },
      {
        datetime: "2024-06-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "6.186666667",
        mean: "1.080416667",
      },
      {
        datetime: "2024-07-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "0.227419355",
        mean: "0.089112903",
      },
      {
        datetime: "2024-08-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "0.453225806",
        mean: "0.066397849",
      },
      {
        datetime: "2024-09-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "31.53666667",
        mean: "2.995277778",
      },
      {
        datetime: "2024-10-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "27.21451613",
        mean: "6.52311828",
      },
      {
        datetime: "2024-11-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "1.58",
        max: "189.715",
        mean: "62.45277778",
      },
      {
        datetime: "2024-12-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "0.185483871",
        max: "150.4564516",
        mean: "51.28796712",
      },
      {
        datetime: "2025-01-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "real",
        min: "1.1355",
        max: "94.1113",
        mean: "25.9737",
      },
      {
        datetime: "2024-07-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "0.09",
        max: "0.09",
        mean: "0.09",
      },
      {
        datetime: "2024-08-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "0.02",
        max: "0.01",
        mean: "0.015",
      },
      {
        datetime: "2024-09-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "0.03",
        max: "0.14",
        mean: "0.09",
      },
      {
        datetime: "2024-10-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "0.37",
        max: "8.44",
        mean: "2.85",
      },
      {
        datetime: "2024-11-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "7.49",
        max: "28.52",
        mean: "18.80",
      },
      {
        datetime: "2024-12-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "8.23",
        max: "31.37",
        mean: "15.81",
      },
      {
        datetime: "2025-01-02T08:02:26.380Z",
        measure: "inflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "9.0",
        max: "40.0",
        mean: "20.0",
      },
      {
        datetime: "2024-01-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1011.3",
        max: "1201.251613",
        mean: "1079.927285",
      },
      {
        datetime: "2024-02-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1025.014286",
        max: "1242.030357",
        mean: "1108.565584",
      },
      {
        datetime: "2024-03-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1023.714516",
        max: "1168.169355",
        mean: "1083.177016",
      },
      {
        datetime: "2024-04-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1029.625",
        max: "1089.005",
        mean: "1047.318889",
      },
      {
        datetime: "2024-05-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1024.166129",
        max: "1243.214516",
        mean: "1054.45914",
      },
      {
        datetime: "2024-06-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1023.053333",
        max: "1049.918333",
        mean: "1029.475",
      },
      {
        datetime: "2024-07-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1022.585484",
        max: "1025.408065",
        mean: "1023.808065",
      },
      {
        datetime: "2024-08-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1022.380645",
        max: "1026.098387",
        mean: "1023.150269",
      },
      {
        datetime: "2024-09-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1022.296667",
        max: "1098.263333",
        mean: "1030.777778",
      },
      {
        datetime: "2024-10-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1022.2",
        max: "1091.182258",
        mean: "1039.719355",
      },
      {
        datetime: "2024-11-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1033.15",
        max: "1308.463333",
        mean: "1134.930972",
      },
      {
        datetime: "2024-12-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1024.885484",
        max: "1258.41129",
        mean: "1126.488254",
      },
      {
        datetime: "2025-01-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "real",
        min: "1011.3",
        max: "1201.25",
        mean: "1079.93",
      },
      {
        datetime: "2024-07-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "forecast",
        min: "1024.34",
        max: "1024.34",
        mean: "1024.34",
      },
      {
        datetime: "2024-08-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "forecast",
        min: "1023.64",
        max: "1025.13",
        mean: "1024.15",
      },
      {
        datetime: "2024-09-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "forecast",
        min: "1045.12",
        max: "1073.27",
        mean: "1050.78",
      },
      {
        datetime: "2024-10-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "forecast",
        min: "1038.04",
        max: "1066.87",
        mean: "1049.72",
      },
      {
        datetime: "2024-11-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "forecast",
        min: "1167.08",
        max: "1292.78",
        mean: "1234.93",
      },
      {
        datetime: "2024-12-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "forecast",
        min: "1133.75",
        max: "1245.46",
        mean: "1201.49",
      },
      {
        datetime: "2025-01-02T08:02:26.380Z",
        measure: "storage",
        unit: " 1000 M3",
        value_type: "forecast",
        min: "1120.17",
        max: "1188.3",
        mean: "1154.93",
      },
      {
        datetime: "2024-01-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "3.477419355",
        mean: "0.577160442",
      },
      {
        datetime: "2024-02-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "7.744827586",
        mean: "0.916969786",
      },
      {
        datetime: "2024-03-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "9.57704918",
        mean: "1.960089542",
      },
      {
        datetime: "2024-04-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "4.883333333",
        mean: "2.794743409",
      },
      {
        datetime: "2024-05-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "9.138709677",
        mean: "5.61884645",
      },
      {
        datetime: "2024-06-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "13.51166667",
        mean: "9.552570621",
      },
      {
        datetime: "2024-07-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "17.69032258",
        mean: "13.00524194",
      },
      {
        datetime: "2024-08-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "15.02096774",
        mean: "11.40748534",
      },
      {
        datetime: "2024-09-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "6.748333333",
        mean: "4.796554878",
      },
      {
        datetime: "2024-10-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "3.45483871",
        mean: "1.955016412",
      },
      {
        datetime: "2024-11-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "1.11",
        mean: "0.5550587",
      },
      {
        datetime: "2024-12-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "1.853225806",
        mean: "0.438396057",
      },
      {
        datetime: "2025-01-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "real",
        min: "0",
        max: "3.4774",
        mean: "0.5772",
      },
      {
        datetime: "2024-07-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "13.01",
        max: "13.01",
        mean: "13.01",
      },
      {
        datetime: "2024-08-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "10.40",
        max: "13.01",
        mean: "12.38",
      },
      {
        datetime: "2024-09-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "3.69",
        max: "6.5",
        mean: "4.16",
      },
      {
        datetime: "2024-10-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "1.75",
        max: "3.2",
        mean: "2.22",
      },
      {
        datetime: "2024-11-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "0.61",
        max: "1.0",
        mean: "0.8",
      },
      {
        datetime: "2024-12-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "1.53",
        max: "1.8",
        mean: "1.7",
      },
      {
        datetime: "2025-01-02T08:02:26.380Z",
        measure: "outflow",
        unit: "M3/S",
        value_type: "forecast",
        min: "1.2",
        max: "3.0",
        mean: "1.84",
      },
    ],
  };
};
