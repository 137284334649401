import axios from "axios";
import { API_ALL_SENSORS_MEASURE } from "../../config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import {
  FETCH_ALL_WATER_SENSORS_MEASURE_REQUEST,
  fetchWaterSensorsMeasureFailure,
  fetchWaterSensorsMeasureSuccess,
} from "../../store/actions/realtimeWater.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";

const getWaterSensorsMeasure = async (token, system) => {
  const url = `${API_ALL_SENSORS_MEASURE}`;
  const data = { field_name: system };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(url, data, { headers });
  return response.data;
};

function* handleWaterSensorsMeasure(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const system = action?.payload;
    const response = yield call(getWaterSensorsMeasure, token, system);
    yield put(fetchWaterSensorsMeasureSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWaterSensorsMeasureFailure(error.message));
  }
}

export function* watchGetWaterSensorsMeasure() {
  yield takeLatest(
    FETCH_ALL_WATER_SENSORS_MEASURE_REQUEST,
    handleWaterSensorsMeasure
  );
}
