import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_SENSORS } from "../../config/api";
import { fetchOveviewData } from "../../store/actions/oveview.actions";
import {
  ADD_SENSOR,
  DELETE_SENSOR,
  UPDATE_SENSOR,
  addSensorFailure,
  addSensorSuccess,
  deleteSensorFailure,
  deleteSensorSuccess,
  updateSensorFailure,
  updateSensorSuccess,
} from "../../store/actions/sensors.actions";
import { fetchWaterAuthorityRequest } from "../../store/actions/waterAuthority.actions";
import { fetchWaterSystemsRequest } from "../../store/actions/waterSystems.actions";

const addSensor = async (token, sensor) => {
  const url = `${API_SENSORS}`;
  const data = sensor;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(url, { data }, { headers });
  return response.data;
};

function* handleAddSensor(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const waterAuthority = yield select(
      (state) => state.waterAuthority.data.id
    );
    let sensor = action?.payload;
    const response = yield call(addSensor, token, sensor);
    yield put(addSensorSuccess(response));
    yield put(fetchWaterAuthorityRequest(waterAuthority));
    yield put(fetchWaterSystemsRequest(waterAuthority));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(addSensorFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchAddSensor() {
  yield takeLatest(ADD_SENSOR, handleAddSensor);
}

const deleteSensor = async (token, sensor) => {
  const system = `${API_SENSORS}/${sensor?.id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.delete(system, { headers });
  return response.data;
};

function* handleDeleteSensor(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const waterAuthorityId = yield select(
      (state) => state.waterAuthority.data.id
    );
    const sensor = action?.payload;
    const response = yield call(deleteSensor, token, sensor);
    yield put(deleteSensorSuccess(response));
    yield put(fetchWaterSystemsRequest(waterAuthorityId));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(deleteSensorFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchDeleteSensor() {
  yield takeLatest(DELETE_SENSOR, handleDeleteSensor);
}

const updateSensor = async (token, sensor) => {
  const url = `${API_SENSORS}/${sensor.id}`;
  const data = sensor;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.put(url, { data }, { headers });
  return response.data;
};

function* handleUpdateSensor(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const waterAuthority = yield select(
      (state) => state.waterAuthority.data.id
    );
    let sensor = action?.payload;
    const response = yield call(updateSensor, token, sensor);
    yield put(updateSensorSuccess(response));
    yield put(fetchWaterAuthorityRequest(waterAuthority));
    yield put(fetchWaterSystemsRequest(waterAuthority));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(updateSensorFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchUpdateSensor() {
  yield takeLatest(UPDATE_SENSOR, handleUpdateSensor);
}
