import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

const DataGridUsers = ({ columns, rows }) => {
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default DataGridUsers;
