import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateWaterAuthority } from "../../store/actions/waterAuthority.actions";

const SlidebarFlow = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const waterSystemSelected = useSelector((state) => state.waterSystem.data);
  // const waterSystems = useSelector((state) => state.waterSystems).data;
  // const waterSystem = waterSystems?.find((f) => f.id === waterSystemSelected);
  const waterAuthority = useSelector((state) => state.waterAuthority).data;

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const saveFlow = () => {
    const flow = props.flow;
    const updatedWaterAuthority = { ...waterAuthority };
    const cleanedNodes = flow.nodes.map((node) => {
      return {
        ...node,
        type: "default",
        data: { ...node.data, onRemove: "", onEdit: "" },
      };
    });
    const cleanedEdges = flow.edges.map((edge) => {
      return { ...edge, type: "default", data: { ...edge.data, onRemove: "" } };
    });
    const cleanedFlow = { nodes: cleanedNodes, edges: cleanedEdges };

    updatedWaterAuthority.attributes = {
      ...updatedWaterAuthority.attributes,
      diagramSchema: cleanedFlow,
    };
    dispatch(updateWaterAuthority(updatedWaterAuthority));
  };

  const sourceName = t("Water source");
  const userName = t("Water user");

  return (
    <aside>
      <div className="description">
        {t("You can drag these nodes to the pane on the left.")}
      </div>
      <div
        className="dndnode source"
        onDragStart={(event) => onDragStart(event, "default", sourceName)}
        draggable
      >
        {sourceName}
      </div>
      <div
        className="dndnode user"
        onDragStart={(event) => onDragStart(event, "output", userName)}
        draggable
      >
        {userName}
      </div>

      <div style={{ textAlign: "center" }}>
        <Button variant="contained" onClick={() => saveFlow()}>
          {t("flow-save")}
        </Button>
      </div>
    </aside>
  );
};

export default SlidebarFlow;
