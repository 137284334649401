import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWaterSensorMeasureDataRequest } from "../../store/actions/realtimeSensorMeasure.actions";
import dayjs from "dayjs";
import LineChartComponent from "shared-components/src/components/charts/lineChart.component";

const HistoricalDataComponent = ({ t, systemName }) => {
  const [sensorMeasure, setSensorMeasure] = useState("");

  const handleChangeSensor = (event) => {
    setSensorMeasure(event.target.value);
  };
  const waterSensorsMeasure = useSelector(
    (state) => state.waterSensorsMeasure.data
  );
  const waterSystem = useSelector((state) => state.waterSystem);
  const dispatch = useDispatch();

  const sensorMeasureData = useSelector(
    (state) => state.waterSensorMeasureData.data
  );

  const sensorChartData = Array.isArray(sensorMeasureData)
    ? {
        labels: /* getlabels(sensorMeasureData) */ [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
        ],
        datasets: getDatasets(sensorMeasureData),
      }
    : null;

  function getlabels(sensorData) {
    if (Array.isArray(sensorData)) {
      const sensorDataReverse = sensorData.slice(-6).reverse();
      return sensorDataReverse.map((data) =>
        dayjs(data.time_of_measure).format("MMM")
      );
    }
  }

  function getDatasets(sensorData) {
    if (Array.isArray(sensorData)) {
      const sensorDataReverse = sensorData.slice(-6).reverse();
      const label = sensorData?.[0]?.unit_of_measurement;
      const data = sensorDataReverse.map((data) => data.value);
      const datasets = [
        {
          label: label,
          data: data,
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 2,
          backgroundColor: "rgb(75, 192, 192)",
        },
      ];
      return datasets;
    }
  }

  const getSensorMeasureName = () => {
    if (!waterSensorsMeasure) return [];

    const datastream_name = waterSensorsMeasure
      .filter((sensor) => sensor !== null)
      .map((sensor) => sensor.datastream_name);
    return datastream_name;
  };

  const datastream_name = getSensorMeasureName();

  const sensorMeasureName = (datastream_name) => {
    const parts = datastream_name.split("_");
    return t(parts.slice(-2).join(" "));
  };

  useEffect(() => {
    if (waterSystem && sensorMeasure) {
      dispatch(fetchWaterSensorMeasureDataRequest(systemName, sensorMeasure));
    }
  }, [waterSystem, dispatch, sensorMeasure, systemName]);

  useEffect(() => {
    setSensorMeasure("");
    dispatch(fetchWaterSensorMeasureDataRequest(systemName, null));
  }, [waterSystem, dispatch, systemName]);

  return (
    <>
      <Typography variant="body1" fontWeight="bold">
        {t("real-time-page.last-Six-Month")}
      </Typography>
      <Box height="auto" width="100%" marginTop={2}>
        <FormControl sx={{ width: "fit-content", minWidth: "180px" }}>
          <InputLabel>
            {t("real-time-page.sensor-measure-selector-label")}
          </InputLabel>
          <Select
            labelId="sensor-select-label"
            id="sensor-select"
            value={sensorMeasure}
            label={t("real-time-page.sensor-measure-selector-label")}
            onChange={handleChangeSensor}
          >
            {datastream_name.map((datastream_name, index) => (
              <MenuItem key={index} value={datastream_name}>
                {sensorMeasureName(datastream_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LineChartComponent data={sensorChartData}></LineChartComponent>
      </Box>
    </>
  );
};

export default HistoricalDataComponent;
