import { all } from "redux-saga/effects";
import {
  watchCheckTokenLogin,
  watchLogin,
} from "shared-components/src/components/login/saga/auth.saga";
import watchMe from "shared-components/src/components/users/saga/users.saga";
import { watchGetWeatherCondition } from "shared-components/src/components/weatherForecast/saga/weatherForecast.saga";
import { watchGetAggregateMonthMeasure } from "./sagas/realtimeAggregateMonthMeasure.saga";
import { watchGetDataFromModel } from "./sagas/realtimeDataFromModel.saga";
import { watchGetWaterSensorsMeasure } from "./sagas/realtimeWater.saga";
import { watchGetWaterSensorMeasureData } from "./sagas/realtimeWaterSensorMeasure.saga";
import {
  watchAddSensor,
  watchDeleteSensor,
  watchUpdateSensor,
} from "./sagas/sensors.saga";
import watchGetWaterAuthority, {
  watchUpdateWaterAuthority,
} from "./sagas/waterAuthority.saga";
import {
  watchAddWaterSystem,
  watchDeleteWaterSystem,
  watchGetWaterSystems,
  watchUpdateWaterSystem,
} from "./sagas/waterSystems.saga";
import { watchSignup } from "./sagas/signup.saga";
import watchWaterAuthorities from "./sagas/waterAuthorities.saga";
import watchUsersByWaterAuthority, {
  watchUpdateUser,
} from "./sagas/users.saga";

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchCheckTokenLogin(),
    watchMe(),
    watchGetWaterAuthority(),
    watchGetWaterSystems(),
    watchAddWaterSystem(),
    watchUpdateWaterSystem(),
    watchDeleteWaterSystem(),
    watchGetWeatherCondition(),
    watchUpdateWaterAuthority(),
    watchAddSensor(),
    watchGetWaterSensorMeasureData(),
    watchGetWaterSensorsMeasure(),
    watchGetAggregateMonthMeasure(),
    watchGetDataFromModel(),
    watchDeleteSensor(),
    watchUpdateSensor(),
    watchDeleteWaterSystem(),
    watchSignup(),
    watchWaterAuthorities(),
    watchUsersByWaterAuthority(),
    watchUpdateUser(),
  ]);
}
