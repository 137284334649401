import axios from "axios";
import {
  API_WATER_AUTHORITY,
  customTokenKey,
  //localTokenApiKey,
} from "../../config/api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_WATER_AUTHORITIES_REQUEST,
  fetchWaterAuthoritiesFailure,
  fetchWaterAuthoritiesSuccess,
} from "../../store/actions/waterAuthorities.actions";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { hideProgress } from "shared-components/src/components/feedback/store/circularProgress.actions";

const getWaterAuthorities = async (token) => {
  const url = `${API_WATER_AUTHORITY}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function* handleWaterAuthorities() {
  try {
    const token = customTokenKey;
    const response = yield call(getWaterAuthorities, token);
    yield put(fetchWaterAuthoritiesSuccess(response));
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWaterAuthoritiesFailure(error.message));
  }
}

export default function* watchWaterAuthorities() {
  yield takeLatest(FETCH_WATER_AUTHORITIES_REQUEST, handleWaterAuthorities);
}
