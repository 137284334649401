import { Fragment, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DataGridUsers from "shared-components/src/components/dataGrid/DataGrid.component";
import { useColumns } from "../utils/usersDataGridColumnsConf";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersRequest } from "../store/actions/users.actions";

const UsersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.data);
  const loggedUser = useSelector((state) => state.user.data);
  const columns = useColumns(dispatch);

  const rows = useMemo(() => {
    return (
      users
        ?.filter((user) => user.email !== loggedUser?.email)
        .map((user) => ({
          id: user.id,
          confirmed: user.confirmed,
          name: user.name || "-",
          surname: user.surname || "-",
          email: user.email,
          notes: user.notes || "-",
        })) || []
    );
  }, [users, loggedUser]);

  useEffect(() => {
    dispatch(fetchUsersRequest());
  }, [dispatch]);
  return (
    <Fragment>
      <h3>{t("users-page.title")}</h3>
      <DataGridUsers columns={columns} rows={rows} />
    </Fragment>
  );
};

export default UsersPage;
