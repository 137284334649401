import {
  ADD_WATER_SYSTEM,
  ADD_WATER_SYSTEM_FAILURE,
  ADD_WATER_SYSTEM_SUCCESS,
  FETCH_WATER_SYSTEMS_FAILURE,
  FETCH_WATER_SYSTEMS_REQUEST,
  FETCH_WATER_SYSTEMS_SUCCESS,
  UPDATE_WATER_SYSTEM,
  DELETE_WATER_SYSTEM,
  DELETE_WATER_SYSTEM_FAILURE,
  DELETE_WATER_SYSTEM_SUCCESS,
} from "../actions/waterSystems.actions";

const initialState = {
  data: [],
  loading: false,
};

const waterSystems = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATER_SYSTEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_WATER_SYSTEMS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_WATER_SYSTEMS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_WATER_SYSTEM:
      return {
        ...state,
        loading: true,
      };
    case ADD_WATER_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_WATER_SYSTEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_WATER_SYSTEM:
      return {
        ...state,
        loading: true,
      };
    case DELETE_WATER_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_WATER_SYSTEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_WATER_SYSTEM:
      const updatedData = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
      return {
        ...state,
        data: updatedData,
      };
    default:
      return state;
  }
};

export default waterSystems;
