import { Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import MapDrawComponent from "shared-components/src/components/map/mapDraw.component";
import { v4 as uuidv4 } from "uuid";
import { calculateCentroid } from "shared-components/src/components/map/utils";

const MapAddUpdateFieldComponent = (props) => {
  const { values, setFieldValue, shapefileData, setShapefileData } =
    props?.formikDialogProps;
  const [polygons, setPolygons] = useState(values?.polygons);
  const mapCenter =
    polygons.length > 0
      ? calculateCentroid(polygons[0].position)
      : [40.356862371325384, 9.240084014976134];

  useEffect(() => {
    const savedData = sessionStorage.getItem("shapefileData");
    if (savedData) {
      const savedDataParsed = JSON.parse(savedData);
      setShapefileData(savedDataParsed);
    }
  }, [setShapefileData]);

  useEffect(() => {
    if (shapefileData && shapefileData[0]) {
      const firstFeature = shapefileData[0];
      const newCoordinates = firstFeature.geometry.coordinates[0].map(
        ([lat, lng]) => [lng, lat]
      );

      const firstPolygon = {
        id: uuidv4(),
        position: newCoordinates,
        color: "blue",
      };

      setPolygons([firstPolygon]);
      setFieldValue("polygons", [firstPolygon]);

      sessionStorage.removeItem("shapefileData");
    }
  }, [shapefileData, setFieldValue]);

  const handlePolygonsChange = (editedPolygons) => {
    setPolygons(editedPolygons);
    setFieldValue("polygons", editedPolygons);
  };

  return (
    <Fragment>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12}>
          <MapDrawComponent
            polygons={polygons}
            onPolygonsChange={handlePolygonsChange}
            mapCenter={mapCenter}
          ></MapDrawComponent>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default MapAddUpdateFieldComponent;
