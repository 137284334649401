import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MenuSide from "shared-components/src/components/drawer/menuSide.component";
import AlertInfoComponent from "shared-components/src/components/feedback/AlertInfo.component";
import CircularProgressComponent from "shared-components/src/components/feedback/CircularProgress.component";
import { showProgress } from "shared-components/src/components/feedback/store/circularProgress.actions";
import { logout } from "shared-components/src/components/login/store/auth.actions";
import HeaderBar from "shared-components/src/components/toolbar/headerBar.component";
import { meRequest } from "shared-components/src/components/users/store/users.actions";
import logo from "../assets/images/Acquaount-logo.png";
import { languages, languagesFlags } from "../config/languagesEnum";
//import { menuList } from "../config/menuList";
import { fetchWaterAuthorityRequest } from "../store/actions/waterAuthority.actions";
import { fetchWaterSystemsRequest } from "../store/actions/waterSystems.actions";

const drawerWidth = 240;

const MainLayout = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user).data;
  const location = useLocation();
  const isAdmin = user?.role?.name === "WaterAdministrator";

  const menuList = {
    home: { label: "home", icon: "home", path: "home" },
    overview: { label: "overview", icon: "visibility", path: "overview" },
    realtime: { label: "realtime", icon: "show_chart", path: "realtime" },
    ...(isAdmin
      ? { users: { label: "users", icon: "people", path: "users" } }
      : {}),
    wiki: { label: "wiki", icon: "menu_book", path: "wiki" },
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const title = "Water Dashboard";

  useEffect(() => {
    dispatch(meRequest());
  }, [dispatch]);

  useEffect(() => {
    const water_authority = user?.water_authority?.id;
    if (user?.water_authority !== undefined && water_authority === undefined) {
      dispatch(logout());
    }
    if (water_authority) {
      dispatch(showProgress());
      dispatch(fetchWaterAuthorityRequest(water_authority));
      dispatch(fetchWaterSystemsRequest(water_authority));
    }
  }, [user, dispatch]);

  useEffect(() => {
    setMobileOpen(false);
    document.documentElement.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <AlertInfoComponent />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <HeaderBar
          languages={languages}
          languagesFlags={languagesFlags}
          title={title}
          handleDrawerToggle={handleDrawerToggle}
          name={user?.username}
        ></HeaderBar>
        <CircularProgressComponent />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <MenuSide menuList={menuList} logo={logo}></MenuSide>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            <MenuSide menuList={menuList} logo={logo}></MenuSide>
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingLeft: 4,
            paddingRight: 4,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            background: "#f6f6f6",
            minHeight: "100vh",
          }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    </>
  );
};

MainLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainLayout;
