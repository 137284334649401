import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";

const SelectVolumesModels = ({ dataModel }) => {
  const [variable, setVariables] = useState(dataModel);

  const handleChange = (event) => {
    setVariables(event.target.value);
    dataModel(event.target.value);
  };

  return (
    <FormControl sx={{ width: "fit-content", minWidth: "150px" }}>
      <InputLabel id="select-volumes-label">
        {t("real-time-page.variables-selector-label")}
      </InputLabel>
      <Select
        labelId="select-volumes-label"
        id="select-volumes"
        value={variable}
        label={t("real-time-page.variables-selector-label")}
        onChange={handleChange}
      >
        <MenuItem value={"inflow"}>Total IN Flow</MenuItem>
        <MenuItem value={"outflow"}>Total OUT Flow</MenuItem>
        <MenuItem value={"storage"}>Volumes</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectVolumesModels;
